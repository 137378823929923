var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-14",attrs:{"id":"bills"}},[_c('notifications',{style:({marginBottom:'2px'}),attrs:{"group":"public","position":"bottom center"}}),(_vm.editQuestion.isEdit || _vm.addQuestion)?_c('handel-question',_vm._b({on:{"handleClose":_vm.handleClose,"handelRequest":_vm.handelRequest}},'handel-question',{
      isEdit: _vm.editQuestion.isEdit,
      editanswer: _vm.editQuestion.editanswer,
      editquestion: _vm.editQuestion.editquestion,
    },false)):_vm._e(),(_vm.deleteConfirm >= 0)?_c('confirm-delete',_vm._b({on:{"handleClose":_vm.handleClose,"handelRequest":_vm.handelRequest}},'confirm-delete',{ id: _vm.deleteConfirm, isOpen: true },false)):_vm._e(),_c('v-row',{staticClass:"d-flex flex-column text-center"},[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('h1',{staticClass:"display-3 font-weight-bold mb-3 text-grey"},[_vm._v(" "+_vm._s(_vm.$t('faqs.faqs'))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('bact-table',_vm._b({scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var data = ref.data;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"secondary lighten-2"},on:{"click":function($event){return _vm.handelEdit(data)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(_vm._s(_vm.editIcon))])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"secondary lighten-2"},on:{"click":function($event){_vm.deleteConfirm = data.id}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.deleteIcon))])],1)]}}])},'bact-table',{
          headers: _vm.headers,
          actionName: 'FAQs/fetchFAQs',
          totalCount: 'FAQs/FAQsCount',
        },false),[_c('download-excel',{attrs:{"data":_vm.faqs,"name":"FAQs"}},[_c('v-btn',{staticClass:"white--text ma-2",attrs:{"color":this.YColor}},[_c('v-icon',[_vm._v(_vm._s(this.downloadIcon))]),_vm._v(" "+_vm._s(_vm.$t('table.export'))+" ")],1)],1),_c('v-btn',{staticClass:"ml-3 mb-3 mb-md-0 text-lg-h6",attrs:{"color":_vm.primaryColor,"dark":""},on:{"click":function($event){_vm.addQuestion = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.plusIcon))]),_vm._v(" "+_vm._s(_vm.$t('faqs.newFaq'))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }