<template>
  <v-container id="bills" class="pa-14">
     <notifications group="public" position="bottom center"  :style="{marginBottom:'2px'}"/>
    <handel-question
      v-if="editQuestion.isEdit || addQuestion"
      v-bind="{
        isEdit: editQuestion.isEdit,
        editanswer: editQuestion.editanswer,
        editquestion: editQuestion.editquestion,
      }"
      @handleClose="handleClose"
      @handelRequest="handelRequest"
    ></handel-question>
    <confirm-delete
      v-if="deleteConfirm >= 0"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleClose="handleClose"
      @handelRequest="handelRequest"
    ></confirm-delete>

    <!-- table -->
    <v-row class="d-flex flex-column text-center">
      <v-col cols="12" class="mb-4">
        <h1 class="display-3 font-weight-bold mb-3 text-grey">
          {{ $t('faqs.faqs') }}
        </h1>
      </v-col>
      <v-col cols="12">
        <bact-table
          v-bind="{
            headers: headers,
            actionName: 'FAQs/fetchFAQs',
            totalCount: 'FAQs/FAQsCount',
          }"
        >
          <download-excel 
            :data="faqs"
            name="FAQs">
            <v-btn class="white--text ma-2" :color="this.YColor">
            <v-icon>{{this.downloadIcon}}</v-icon>
            {{ $t('table.export') }} 
          </v-btn>
          </download-excel>
          <v-btn
            class="ml-3 mb-3 mb-md-0 text-lg-h6"
            :color="primaryColor"
            dark
            @click="addQuestion = true"
          >
            <v-icon>{{ plusIcon }}</v-icon>
            {{ $t('faqs.newFaq') }}
          </v-btn>
          <template v-slot:action="{ data }">
            <v-btn
              text
              icon
              color="secondary lighten-2"
              @click="handelEdit(data)"
            >
              <v-icon color="green">{{ editIcon }}</v-icon>
            </v-btn>
            <v-btn
              text
              icon
              color="secondary lighten-2"
              @click="deleteConfirm = data.id"
            >
              <v-icon color="red">{{ deleteIcon }}</v-icon>
            </v-btn>
          </template>
        </bact-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BactTable from "./Components/FAQsBaseTable";
import HandelQuestion from "./Components/dialogQuestion";
import ConfirmDelete from "../../Components/confirmDelete";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FAQs",

  components: { BactTable, HandelQuestion, ConfirmDelete },

  data() {
    return {
      headers: [
        { text: this.$t('faqs.question'), value: "question" },
        { text: this.$t('faqs.answer'), value: "answer" },
        { text: "", value: "actions", align: "center" },
      ],
      faqs: [],
      addQuestion: false,
      deleteConfirm: -1,
      editQuestion: {
        isEdit: false,
        editanswer: "",
        editquestion: "",
        editId: "",
      },
    };
  },

  created () {
    this.fetchFAQs().then(() => {
      this.faqs = this.getFaqs;
    })
  },

  computed: {
    ...mapGetters("FAQs", ["getFaqs"])
  },

  methods: {
    ...mapActions("FAQs", ["addFAQ", "editFAQ", "delete", "fetchFAQs"]),

    async handelRequest(data) {
      let mydata;
      if (data.method === "addFAQ")
        mydata = { answer: data.answer, question: data.question };
      else if (data.method === "editFAQ")
        mydata = {
          answer: data.answer,
          question: data.question,
          id: this.editQuestion.editId,
        };
      else mydata = data.id;
      this.handleClose();
      await this[data.method](mydata);
    },

    handleClose() {
      this.addQuestion = false;
      this.editQuestion = {
        isEdit: false,
        editanswer: "",
        editquestion: "",
        editId: "",
      };
      this.deleteConfirm = -1;
    },
    handelEdit(data) {
      this.editQuestion = {
        isEdit: true,
        editanswer: data.answer,
        editquestion: data.question,
        editId: data.id,
      };
    },
  },
};
</script>
