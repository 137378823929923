<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <v-form ref="form" lazy-validation v-on:submit.prevent>
        <v-card-title class="justify-center">
          <span class="headline">{{
            isEdit ? this.$t('faqs.editFaq') : this.$t('faqs.newFaq')
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="question"
                  :label="$t('faqs.question')"
                  :rules="questionRules"
                  
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-1">
                <v-textarea
               
                  v-model="answer"
                  :label="$t('faqs.question')"
                 
                  :rules="answerRules"
                  rows="2"
                  auto-grow
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <base-button
            :options="{ color: this.grayBack50 }"
            text
            @click="close"
          >
            Cancel
          </base-button>
          <base-button @click="save" options> Save </base-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";
export default {
  name: "HandelQuestion",
  props: {
    isEdit: Boolean,
    editanswer: String,
    editquestion: String,
  },
  data() {
    return {
      questionRules: [(v) => !!v || "السؤال مطلوب"],
      answerRules: [(v) => !!v || "الجواب مطلوب"],
      question: "",
      answer: "",
      dialog: true,
    };
  },
  methods: {
    close() {
       this.dialog = false;
      (this.question = ""),
        (this.answer = ""),
        this.$refs.form.resetValidation();
      this.$emit("handleClose");
    },
    save() {
      if (!this.$refs.form.validate()) return;
      let mydata = { answer: this.answer, question: this.question,method:this.isEdit?'editFAQ':'addFAQ' };
        this.$emit("handelRequest", mydata);
    },
  },
  created() {
    if (this.isEdit) {
      (this.answer = this.editanswer), (this.question = this.editquestion);
    }
  },

  components: { BaseButton },
};
</script>


<style scoped>


</style>